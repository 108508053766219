import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import { classLists } from "../../helpers/Formatter";
import APIHelper from "../../helpers/APIHelper";
import Pagination from "../../components/Paginantion";

const ViewAirdrop = ({ airdrops, status }) => {
	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-5">
				{airdrops.map((airdrop, index) => (
					<div
						key={index}
						className="relative rounded py-5 px-5"
						style={{
							background: `url('${airdrop.banner}') no-repeat center center / 100% 100%`,
							height: "145px",
						}}
					>
						<div className="flex items-center">
							<img src={airdrop.logo} alt={airdrop.nama} width={40} height={40} className="rounded-full bg-blue-900 p-2" />
							<h3 className="text-xl ml-3">⭐️ {airdrop.nama}</h3>
						</div>
						<div className="mt-3">
							<div className="text-xs text-[#C8CAEF]" dangerouslySetInnerHTML={{ __html: airdrop.info.replace(/\r\n|\n|\r/gm, "<br />") }} />
						</div>
						{status !== "ended" && (
							<div className="absolute left-0 bottom-2 w-full text-center">
								<a href={status === "active" ? airdrop.link_join : airdrop.link_result}>
									<button className="w-[100px] bg-[#0186F6] rounded-full px-2 text-sm">{status === "active" ? "Join" : "View Details"}</button>
								</a>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default function Airdrops() {
	const itemsPerPage = 10;
	const [tab, setTab] = useState("active");
	const [isLoading, setIsLoading] = useState(false);
	const [airdrops, setAirdrops] = useState([]);
	const [query, setQuery] = useState("");
	const [pageCount, setPageCount] = useState(0);
	const [currentPageItems, setCurrentPageItems] = useState([]);
	const { user, isConnected } = useContext(AuthContext);

	const getAirdrops = async (status) => {
		setIsLoading(true);
		const response = await APIHelper.getAirdropByStatus(status, user?.address);
		if (response.status === "success") {
			setAirdrops(response.result);
			setIsLoading(false);
		}
	};

	const handlePageClick = (event) => {
		const selectedPage = event.selected;
		const offset = (selectedPage * itemsPerPage) % airdrops.length;
		setPageCount(Math.ceil(airdrops.length / itemsPerPage));
		setCurrentPageItems(airdrops.slice(offset, offset + itemsPerPage));
	};

	const search = (query) => {
		const filtered = airdrops.filter((airdrop) => {
			return airdrop.nama.toLowerCase().includes(query.toLowerCase());
		});
		setPageCount(Math.ceil(filtered.length / itemsPerPage));
		setCurrentPageItems(filtered.slice(0, itemsPerPage));
	};

	useEffect(() => {
		if (query) {
			search(query);
		} else {
			setPageCount(Math.ceil(airdrops.length / itemsPerPage));
			setCurrentPageItems(airdrops.slice(0, itemsPerPage));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		setPageCount(Math.ceil(airdrops.length / itemsPerPage));
		setCurrentPageItems(airdrops.slice(0, itemsPerPage));
	}, [airdrops]);

	useEffect(() => {
		getAirdrops(tab);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab, isConnected]);

	useEffect(() => {
		document.title = `Free Crypto Airdrops Token | Kommunitas`;
	}, []);

	return (
		<div className="main-container">
			<div className="text-left">
				<h1 className="title-page">Free Airdrops Crypto Tokens</h1>
				<nav>
					<ul className="breadcrumb text-sm">
						<li>
							<Link to="/" className="txt-ternary">
								Home
							</Link>
						</li>
						<li className="active">Airdrops</li>
					</ul>
				</nav>
			</div>
			<section className="box-parent bg-airdrop my-5">
				<div className="box-desc flex justify-start items-center h-[100px] md:h-[300px]">
					<p className="w-2/3 md:w-1/2 text-sm md:text-xl">Who Doesn{"'"}t Love Free Tokens? We constantly organize Airdrops with our partners so that you can earn more free Tokens!</p>
				</div>
				<div className="text-center mt-[5%]">
					<h3 className="text-3xl txt-shadow">The Projects</h3>
					<div className="flex justify-between md:justify-center my-5">
						<button className={classLists(tab === "active" ? "active" : "", "tab-item")} onClick={() => setTab("active")}>
							Active ⚡️
						</button>
						<button className={classLists(tab === "ended" ? "active" : "", "tab-item")} onClick={() => setTab("ended")}>
							Ended 🏁
						</button>
						<button className={classLists(tab === "distributed" ? "active" : "", "tab-item")} onClick={() => setTab("distributed")}>
							Distributed 💰
						</button>
					</div>
				</div>

				{isLoading ? (
					<div className="flex items-center justify-center">Loading data ...</div>
				) : (
					<div className="box-parent mt-10">
						<div className="flex justify-end">
							<input type="text" placeholder="Search by project name" className="w-1/2 rounded-xl my-3 px-2 py-1 text-black" onChange={(e) => setQuery(e.target.value)} />
						</div>
						{currentPageItems.length > 0 ? (
							<div className="mt-5">
								<ViewAirdrop airdrops={currentPageItems} status={tab} />
								{pageCount > 1 && (
									<div className="mt-5">
										<Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
									</div>
								)}
							</div>
						) : (
							<div className="mt-10 flex items-center justify-center">
								<p className="text-center text-2xl">No {tab} projects available at this time.</p>
							</div>
						)}
					</div>
				)}
			</section>
		</div>
	);
}
