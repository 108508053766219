import React, { useState } from "react";
import { Account } from "./Account";
import weblinkData from "../constants/static/weblink.json";
import imgLogo from "../assets/img/logo.png";

function Header() {
	const [openNavbar, setOpenNavbar] = useState(false);

	const imgIconBurger = () => {
		return (
			<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" fill="white" />
			</svg>
		);
	};

	return (
		<header className="sticky top-0 z-40 w-full">
			<div className="w-full p-1 bg-white text-black text-center text-sm">
				Please always double check that the site is <span className="baumans">earn.kommunitas.net</span> - bookmark it to be safe
			</div>
			<nav className="container mx-auto py-4 flex flex-wrap items-center justify-between">
				<div className="flex items-center text-xl mr-0 md:mr-10">
					<img src={imgLogo} className="mr-2 md:mr-4" loading="lazy" alt="logo" />
					<span className="baumans">Kommunitas</span>
				</div>
				<div className="w-full flex-grow hidden lg:flex lg:items-center lg:w-auto">
					{weblinkData.header.map((val, idx) => (
						<a key={idx} href={val.link} className={"block mr-4 lg:inline-block " + (val.blink && " font-bold")} target="_blank" rel="noreferrer">
							{val.name}
						</a>
					))}
				</div>
				<div className="ml-auto">
					<Account />
					<button className="inline mx-1 px-3 py-2 border rounded hover:text-white hover:border-white lg:hidden" aria-label="BurgerBtn" onClick={() => setOpenNavbar(!openNavbar)}>
						<div className="fill-current h-4 w-4">{imgIconBurger()}</div>
					</button>
				</div>
			</nav>
			<div className={"navbar " + (openNavbar ? "visible" : "")} onClick={() => setOpenNavbar(!openNavbar)}>
				<ul onClick={(e) => e.stopPropagation()}>
					<li>
						<div className="mb-4 flex items-center text-xl">
							<img src={imgLogo} className="mr-2" loading="lazy" alt="logo" />
							<span className="baumans text-black">Kommunitas</span>
						</div>
					</li>
					{weblinkData.header.map((val, idx) => (
						<li key={idx}>
							<a href={val.link} className={"mr-4 text-black " + (val.blink && " font-bold")} target="_blank" rel="noreferrer">
								{val.name}
							</a>
						</li>
					))}
				</ul>
			</div>
		</header>
	);
}

export default Header;
