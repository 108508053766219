import React, { useState, useEffect } from "react";

export default function Modal({ show, setShow, children }) {
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (show === true) {
            setOpenModal(true);
            setShow(false);
        }
    }, [show, setShow]);


    return (
        <div className={"modal-overlay" + (openModal ? " open" : "")} onClick={() => setOpenModal(false)}>
            <div className="modal-box">
                <button onClick={() => setOpenModal(false)} className="modal-close">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <div>{children}</div>
            </div>
        </div>
    );
}
