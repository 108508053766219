import ReactPaginate from "react-paginate";

export default function Pagination({
    handlePageClick,
    pageCount,
}) {
    return (
        <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={(e) => handlePageClick(e)}
            containerClassName={"pagination"}
            pageClassName={"pagination-item"}
            pageLinkClassName={"pagination__item"}
            activeClassName={"pagination__link--active"}
            previousClassName={"pagination__item"}
            nextClassName={"pagination__item"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination-item--disabled"}
            breakLabel={"..."}
            breakClassName={"pagination-item"}
            breakLinkClassName={"pagination__link"}
        />
    )
}