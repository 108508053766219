import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../components/Paginantion";
import { AuthContext } from "../../components/AuthProvider";
import APIHelper from "../../helpers/APIHelper";

const ViewAirdrop = ({ data }) => {
    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                {data.map((reward, index) => (
                    <div
                        key={index}
                        className="relative rounded py-5 px-5"
                        style={{
                            background: `url('${reward.banner}') no-repeat center center / 100% 100%`,
                            height: "145px",
                        }}
                    >
                        <div className="flex items-center">
                            <img
                                src={reward.logo}
                                alt={reward.nama}
                                width={40}
                                height={40}
                                className="rounded-full bg-blue-900 p-2"
                            />
                            <h3 className="text-xl ml-3">⭐️ {reward.nama}</h3>
                        </div>
                        <div className="mt-3">
                            <div className="text-xs text-[#C8CAEF]" dangerouslySetInnerHTML={{ __html: reward.info.replace(/\r\n|\n|\r/gm, "<br />") }} />
                        </div>
                        <div className="absolute left-0 bottom-2 w-full text-center">
                            <a href={reward.link} target="_blank" rel="noreferrer">
                                <button className="w-[120px] bg-[#B915E0] rounded-full px-2 text-sm">Views Details</button>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default function DaysStakerRewards() {
    const itemsPerPage = 10;

    const [stakersRewards, setStakersRewards] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { user, isConnected } = useContext(AuthContext);

    const get730Days = () => {
        setIsLoading(true);
        APIHelper.get730Days(user?.address).then((res) => {
            setStakersRewards(res.result);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        const offset = (selectedPage * itemsPerPage) % stakersRewards.length;
        setPageCount(Math.ceil(stakersRewards.length / itemsPerPage));
        setCurrentPageItems(stakersRewards.slice(offset, offset + itemsPerPage));
    }

    const search = (query) => {
        const filtered = stakersRewards.filter((item) => {
            return item.nama.toLowerCase().includes(query.toLowerCase());
        });
        setPageCount(Math.ceil(filtered.length / itemsPerPage));
        setCurrentPageItems(filtered.slice(0, itemsPerPage));
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            search(query);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        setPageCount(Math.ceil(stakersRewards.length / itemsPerPage));
        setCurrentPageItems(stakersRewards.slice(0, itemsPerPage));
    }, [stakersRewards]);

    useEffect(() => {
        get730Days();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected]);


    return (
        <div className="main-container">
            <div className="text-left">
                <h1 className="title-page">730 Days Stakers Rewards</h1>
                <nav>
                    <ul className="breadcrumb text-sm">
                        <li>
                            <Link to="/" className="txt-ternary">
                                Home
                            </Link>
                        </li>
                        <li className="active">730 Days Stakers Rewards</li>
                    </ul>
                </nav>
            </div>
            <section className="box-parent bg-730-days my-5">
                <div className="box-desc">
                    <p className="w-1/2 text-[20px]  leading-[15px] md:leading-[25px] text-white text-justify">
                        Stake your KOM for 730 days / 2 years period and enjoy not only the biggest yield in KOM but also other projects’ token. Snapshot of addresses will be taken by end of each month and distribution will be done by the following month.
                    </p>
                </div>
                <div className="my-5">
                    <div className="box-desc text-[#C8CAEF]">Full details of 730 Days Stakers Rewards can be found here :</div>
                    <div className="my-5">
                        <a href="https://docs.kommunitas.net/staking/how-you-can-earn-free-tokens-by-doing-nothing" target="_blank" rel="noreferrer">
                            <button className="btn bg-[#B915E0] mr-5">View Details</button>
                        </a>
                    </div>
                </div>
                <h3 className="text-center mt-[10%] text-3xl txt-shadow">Distributed Rewards</h3>
                <div className="my-[2rem]">
                    {
                        isLoading ? (
                            <div className="flex items-center justify-center">
                                Loading data ...
                            </div>
                        ) : (
                                <div className="box-parent mt-20">
                                    <div className="flex justify-end">
                                        <input type="text" placeholder="Search by project name" className="w-1/2 rounded-xl my-3 px-2 py-1 text-black" onChange={(e) => setQuery(e.target.value)} />
                                    </div>
                                    {currentPageItems.length > 0 ? (
                                        <div className="mt-5">
                                        <ViewAirdrop data={currentPageItems} />
                                        {
                                            pageCount > 1 && (
                                                <div className="mt-5">
                                                    <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                                                </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                            <div className="mt-10 flex items-center justify-center">
                                                <p className="text-center text-2xl">No projects available at this time.</p>
                                            </div>
                                    )}
                                </div>
                        )
                    }
                </div>
            </section>
        </div>
    );
}
