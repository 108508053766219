import { createContext, useState, useEffect } from "react"

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [isConnected, setIsConnected] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const user = sessionStorage.getItem("user")
        if (user) {
            setUser(JSON.parse(user))
            setIsConnected(true)
        }
        setLoading(false)
    }, [])

    const connectWallet = (user) => {
        sessionStorage.setItem("user", JSON.stringify(user))
        setUser(user)
        setIsConnected(true)
    }

    const disconnectWallet = () => {
        sessionStorage.removeItem("user")
        setUser(null)
        setIsConnected(false)
    }

    return (
        <AuthContext.Provider
            value={{ user, connectWallet, disconnectWallet, loading, isConnected }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
