import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import { classLists } from "../../helpers/Formatter";
import Modal from "../../components/Modal";
import APIHelper from "../../helpers/APIHelper";
import Pagination from "../../components/Paginantion";

const ViewRewards = ({ data, status, setModal }) => {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-5">
			{data.map((reward, index) => {
				return (
					<div
						key={index}
						className="relative rounded py-5 px-5"
						style={{
							background: `url('${reward.banner}') no-repeat center center / 100% 100%`,
							height: "145px",
						}}
					>
						<div className="flex">
							<img
								src={reward.logo}
								alt={reward.nam}
								width={40}
								height={40}
								className="rounded-full bg-blue-900 p-2"
							/>
							<h3 className="ml-3 text-xl">⭐️ {String(reward.nama).toUpperCase()}</h3>
						</div>
						<div className="w-2/3 my-2">
							<p className="text-xs text-white mt-2">
								Rewards : {reward.reward}
							</p>
						</div>
						{status !== "ended" && (
							<div className="absolute left-5 bottom-5 w-full">
								<a href={status === "active" ? reward.link_join : reward.link_result}>
									<button className="w-[100px] bg-[#0186F6] rounded-full px-2 text-sm">{status === "active" ? "Participate" : "View Details"}</button>
								</a>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default function ReferVoteRewards() {
	const itemsPerPage = 10;
	const [tab, setTab] = useState("active");
	const [isLoading, setIsLoading] = useState(false);
	const [rewardsData, setRewardsData] = useState([]);
	const [query, setQuery] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState({});
	const [pageCount, setPageCount] = useState(0);
	const [currentPageItems, setCurrentPageItems] = useState([]);

	const { user, isConnected } = useContext(AuthContext);

	const getRewards = async (status) => {
		setIsLoading(true);
		const response = await APIHelper.getVSBByStatus(status, user?.address);
		if (response.status === "success") {
			setIsLoading(false);
			setRewardsData(response.result);
		}
	};

	const handlePageClick = (event) => {
		const selectedPage = event.selected;
		const offset = (selectedPage * itemsPerPage) % rewardsData.length;
		setPageCount(Math.ceil(rewardsData.length / itemsPerPage));
		setCurrentPageItems(rewardsData.slice(offset, offset + itemsPerPage));
	}

	const search = (query) => {
		const filtered = rewardsData.filter((item) => {
			return item.nama.toLowerCase().includes(query.toLowerCase());
		}
		);
		setPageCount(Math.ceil(filtered.length / itemsPerPage));
		setCurrentPageItems(filtered.slice(0, itemsPerPage));
	}

	const setModal = (data) => {
		setModalData(data);
		setShowModal(true);
	};

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			search(query);
		}, 500);
		return () => clearTimeout(delayDebounceFn);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query])
	useEffect(() => {
		setPageCount(Math.ceil(rewardsData.length / itemsPerPage));
		setCurrentPageItems(rewardsData.slice(0, itemsPerPage));
	}, [rewardsData]);

	useEffect(() => {
		getRewards(tab);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab, isConnected]);

	return (
		<div className="main-container">
			<div className="text-left">
				<h1 className="title-page">Vote, Share and Buy Rewards</h1>
				<nav>
					<ul className="breadcrumb text-sm">
						<li>
							<Link to="/" className="txt-ternary">
								Home
							</Link>
						</li>
						<li className="active">Vote, Share and Buy Rewards</li>
					</ul>
				</nav>
			</div>
			<section className="box-parent bg-vote-share my-5 min-h-screen">
				<div className="box-desc">
					<div className="w-2/3 md:w-1/2">
						<p className="text-xl txt-shadow text-white leading-[15px] md:leading-[25px]">Earn more project{"'"}s token by following these 4 steps:</p>
						<ol className="my-5 ml-5 list-decimal">
							<li>
								<p>
									Stake more than 3,000 KOM so that you can have KOMV <span className="font-bold text-white">(KOM VOTING)</span> token in your wallet.
								</p>
							</li>
							<li>
								<p>
									Vote in any projects that you would like to participate. <span className="font-bold text-white">(VOTE)</span>
								</p>
							</li>
							<li>
								<p>
									Spread the words and share about this particular project to your social media. <span className="font-bold text-white">(SHARE)</span>
								</p>
							</li>
							<li>
								<p>
									Participate in the sale of that particular project in any round of the launchpad. <span className="font-bold text-white">(BUY)</span>
								</p>
							</li>
						</ol>
						<p className="text-xl txt-shadow text-white leading-[15px] md:leading-[25px]">Make sure you don{"'"}t skip any of the steps above to be eligible for token bonus!</p>
					</div>
				</div>
				<div className="text-center mt-[10%]">
					<h3 className="text-2xl txt-shadow">Projects</h3>
					<div className="flex justify-center my-5">
						<button className={classLists(tab === "active" ? "active" : "", "tab-item")} onClick={() => setTab("active")}>
							Active ⚡️
						</button>
						<button className={classLists(tab === "ended" ? "active" : "", "tab-item")} onClick={() => setTab("ended")}>
							Ended 🏁
						</button>
						<button className={classLists(tab === "distributed" ? "active" : "", "tab-item")} onClick={() => setTab("distributed")}>
							Distributed 💰
						</button>
					</div>
				</div>
				{
					isLoading ? (
						<div className="flex items-center justify-center">
							Loading data ...
						</div>
					) : (
							<div className="box-parent mt-10">
								<div className="flex justify-end">
									<input type="text" placeholder="Search by project name" className="w-1/2 rounded-xl my-3 px-2 py-1 text-black" onChange={(e) => setQuery(e.target.value)} />
								</div>
								{currentPageItems.length > 0 ? (
									<div className="mt-5">
										<ViewRewards data={currentPageItems} status={tab} setModal={(setModal)} />
										{
											pageCount > 1 && (
												<div className="mt-5">
													<Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
												</div>
											)
										}
									</div>
								) : (
									<div className="mt-10 flex items-center justify-center">
										<p className="text-center text-2xl">No {tab} projects available at this time.</p>
									</div>
								)}
							</div>
					)
				}
				<Modal
					show={showModal}
					setShow={() => setShowModal(!showModal)}
				>
					<div className="flex flex-col items-center">
						<div className="bg-blue-900 px-5 py-5 rounded-lg">
							<div className="flex flex-col justify-items-center">
								<div className="flex items-center">
									<img src={modalData.logo} alt="" className="w-10" />
									<h3 className="text-2xl">{modalData.nama}</h3>
								</div>
								<div>
									<p className="text-md mt-2">{modalData.info}</p>
								</div>
								<div className="mt-5">
									<p className="text-lg">Rewards : {modalData.reward}</p>
								</div>
							</div>
							{tab !== "ended" && (
								<div>
									<a href={tab === "active" ? modalData.link_join : modalData.link_result}>
										<button className="btn btn-join">{tab === "active" ? "Participate" : "View Details"}</button>
									</a>
								</div>
							)}
						</div>
					</div>
				</Modal>
			</section>
		</div>
	);
}
