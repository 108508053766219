import { useState, useEffect } from "react";
import APIHelper from "../helpers/APIHelper";

export default function usePopup() {
    const [popup, setPopup] = useState(null);

    useEffect(() => {
        const pageURL = window.location.href;
        APIHelper.getPopup(pageURL).then((res) => {
            if (res.status === "success") setPopup(res.result);
        });
    }, []);

    return popup;
}
