import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import APIHelper from "../../helpers/APIHelper";

export default function MonthlyMillionaire() {
	const { user, isConnected } = useContext(AuthContext);
	const [monthly, setMonthly] = useState([]);
	const [loading, setLoading] = useState(false);

	const getMonthly = () => {
		setLoading(true);
		APIHelper.getMonthly(user?.address)
			.then((res) => {
				setMonthly(res.result);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getMonthly();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConnected]);

	return (
		<div className="main-container">
			<div className="text-left">
				<h1 className="title-page">Monthly Millionaire Partner Sharing</h1>
				<nav>
					<ul className="breadcrumb text-sm">
						<li>
							<Link to="/" className="txt-ternary">
								Home
							</Link>
						</li>
						<li className="active">Monthly Millionaire Partner Sharing</li>
					</ul>
				</nav>
			</div>
			<section className="box-parent bg-monthly-millionaire my-5">
				<div className="box-desc">
					<p className="w-2/3 md:w-1/2 leading-[15px] md:leading-[25px] text-xl text-white">
						Monthly Millionaire Partner Sharing snapshot will be taken every end of each month, 23:59 UTC. (31st January, 28th February, 31st March, 30th April, 31st May, 30th June, 31st
						July, 31st August, 30th September, 31st October, 30th November, 31st December).
					</p>
				</div>
				<div className="my-5">
					<div className="box-desc text-[#C8CAEF]">Full details of Monthly Millionaire Partner Sharing can be found here :</div>
					<div className="my-5">
						<a href="https://docs.kommunitas.net/staking/kom-millionaire-partners" target="_blank" rel="noreferrer">
							<button className="btn btn-shadow mr-5">View Details</button>
						</a>
					</div>
				</div>
				{!loading ? (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:mt-48">
						{monthly.reverse().map((item, index) => (
							<div key={index} className="relative">
								<div className={"bg-box rounded-xl px-6 py-8 md:py-12 md:px-14 " + (isConnected && !item.winner ? "opacity-25" : "")}>
									<img src={item.banner} alt="First Revenue Sharing" className="rounded-xl mx-auto" width={500} height={100} />
									<a href={item.link} target="_blank" rel="noreferrer">
										<button className="w-full btn bg-[#56ADD2] my-5">View Details</button>
									</a>
								</div>
								{isConnected && !item.winner && (
									<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
										<p className="text-white">Not Winner</p>
									</div>
								)}
							</div>
						))}
						<div className="flex items-center justify-center bg-box rounded-xl monthly-coming-soon">
							<p className="text-lg opacity-100">Coming Soon</p>
						</div>
					</div>
				) : (
					<div className="flex items-center justify-center">Loading data...</div>
				)}
			</section>
		</div>
	);
}
