import Layout from "./components/Layout";
import { Routes, Route } from "react-router-dom";
import usePopup from "./hooks/usePopup";

import HomePage from "./pages/HomePage";
import Airdrops from "./pages/Airdrops/Airdrops";
import Quarterly from "./pages/QuarterlyRevenueSharing/QuarterlyRevenueSharing";
import MonthlyMillionaire from "./pages/MonthlyMillionairePartnersSharing/MonthlyMillionaire";
import ReferVoteRewards from "./pages/VoteShareRewards/ReferVoteRewards";
import DaysStakerRewards from "./pages/730Days/DaysStakerRewards";
import Learn2Earn from "./pages/Learn2Earn/Learn2Earn";
import LPStaking from "./pages/LPStaking/LPStaking";
import Announcement from "./components/Announcement";

function App() {
	const popup = usePopup();
	return (
		<Layout>
			{popup && <Announcement data={popup} />}
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/airdrops" element={<Airdrops />} />
				<Route path="/quarterly-revenue-sharing" element={<Quarterly />} />
				<Route
					path="/monthly-millionaire-partners-sharing"
					element={<MonthlyMillionaire />}
				/>
				<Route path="/vote-share-buy-rewards" element={<ReferVoteRewards />} />
				<Route path="/730-days-stakers-rewards" element={<DaysStakerRewards />} />
				<Route path="/learn2earn" element={<Learn2Earn />} />
				<Route path="/lp-staking" element={<LPStaking />} />
				<Route path="*" element={<h1>404 Not Found</h1>} />
			</Routes>
		</Layout>
	);
}

export default App;
