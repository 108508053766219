export const classLists = (...classes) => {
    return classes.filter(Boolean).join(" ")
}

export const addressShort = (address) => {
    return address.slice(0, 6) + "..." + address.slice(-4)
}
export const toTime = (unix) => {
    let milliseconds = unix * 1000;
    let dateObject = new Date(milliseconds);
    return dateObject.toUTCString().slice(0, -7) + " UTC";
};
export const toDateString = (date) => {
    let dateObject = new Date(date);
    return dateObject.toDateString();
}

export const words = (str, num) => {
    return str.split(" ").slice(0, num).join(" ");
}

export const hashCode = (s) => s.split("").reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
