import Header from "./Header"
import Footer from "./Footer"

export default function Layout({ children }) {
    return (
        <>
            <Header />
            <main className="max-w-6xl mx-auto my-10 ellipse">
                {children}
            </main>
            <Footer />
        </>
    )
}
