import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { WalletConnectModal } from '@walletconnect/modal'
import { ParticleNetwork } from "@particle-network/auth";
import { Face } from "@haechi-labs/face-sdk"

export const particle =
	typeof window !== "undefined"
		? new ParticleNetwork({
				projectId: "6bdf1c6d-8bcc-4236-9c01-74d959a3a495",
				clientKey: "cJlaaEqohVdfm9kBeUewY0UTwmcYAvnNJ5rcJ3q9",
				appId: "4c93c3bd-99ac-4ca0-9893-5f5e77a21424",
				chainName: "Polygon",
				chainId: 137,
				wallet: {
					displayWalletEntry: false,
				},
		  })
		: null;

export const face = new Face({
	apiKey: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTuv7ogfAIWDryBqfdcbcxiWdFbrBOfQgQLApeJN9PUMqXLRjNpqUV37ZzYhd3n2VY9zcNNVqgsrfsjx5lGq8wP9fbMf4DwjO1s9wmMCpjIbgkHJK-UcjFQrZH16e44iERtnXbw-eD2EHOswwK9v0cib6YmQ29Fv6_NX31kfJzQQIDAQAB",
	network: "polygon",
});

export const okxwallet = new InjectedConnector();

export const haloWallet = new InjectedConnector();

export const injected = new InjectedConnector();



export const provider = await EthereumProvider.init({
	projectId: '651a68707404d0f1a861f54a4cafa204', // required
	chains: [1], // required
	showQrModal: true // requires @walletconnect/modal
})

export const walletconnect = new WalletConnectModal({
	projectId: '651a68707404d0f1a861f54a4cafa204',
	chains: ['eip155:1']
})

export const walletlink = new WalletLinkConnector({
	url: "https://matic-mainnet.chainstacklabs.com",
	appName: "Kommunitas Earn",
});

export const trustWallet = new InjectedConnector();

export const binanceChain = new InjectedConnector();

export const mathWallet = new InjectedConnector();

export const tokenPocket = new InjectedConnector();

export const safePal = new InjectedConnector();

export const coin98 = new InjectedConnector();

export const connectorByName = {
	MetaMask: {
		name: "Metamask",
		icon: "/img/wallets/metamask.svg",
		connector: injected,
		style: "w-6 h-6"
	},
	HaloWallet: {
		name: "Rabby Wallet",
		icon: "/img/wallets/rabbywallet.png",
		connector: haloWallet,
		style: "w-6 h-6"
	},
	OKXWallet: {
		name: "OKX Wallet",
		icon: "/img/wallets/okxWallet.svg",
		connector: okxwallet,
		style: "w-8 h-8"
	},
	CoinbaseWallet: {
		name: "Coinbase Wallet",
		icon: "/img/wallets/coinbasewallet.svg",
		connector: walletlink,
		style: "w-6 h-6"
	},
	Coin98: {
		name: "Coin98",
		icon: "/img/wallets/coin98.svg",
		connector: coin98,
		style: "w-6 h-6"
	},
	TokenPocket: {
		name: "TokenPocket",
		icon: "/img/wallets/tokenPocket.svg",
		connector: tokenPocket,
		style: "w-6 h-6"
	},
	TrustWallet: {
		name: "TrustWallet",
		icon: "/img/wallets/trustWallet.svg",
		connector: trustWallet,
		style: "w-8 h-8"
	},
	SafePal: {
		name: "SafePal",
		icon: "/img/wallets/safePal.svg",
		connector: safePal,
		style: "w-8 h-8"
	},
	faceWallet: {
		name: "Face Wallet",
		icon: "/img/wallets/facewallet.svg",
		connector: face,
		style: "w-8 h-8"
	},
	ParticleNetwork: {
		name: "Particle Network",
		icon: "/img/wallets/particleNetwork.svg",
		connector: particle,
		style: "w-8 h-8"
	},
	WalletConnect: {
		name: "WalletConnect",
		icon: "/img/wallets/walletConnect.svg",
		connector: walletconnect,
		style: "w-8 h-8"
	},
};
