import React, { useState } from "react";
import imgLogo from "../assets/img/logo.png";
import socialData from "../constants/static/social.json";
import weblinkData from "../constants/static/weblink.json";
import assetData from "../constants/static/asset.json";
import APIHelper from "../helpers/APIHelper";

function Footer() {
	const [email, setEmail] = useState("");
	const [subscribeMessage, setSubscribeMessage] = useState("");
	const subscribeNewsletter = async (e) => {
		e.preventDefault();
		const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		if (pattern.test(email)) {
			const response = await APIHelper.setSubscribe(email);
			if (response.data.status === "success") {
				setSubscribeMessage(response.data.result);
			} else {
				setSubscribeMessage("Error occured, please try again later!");
			}
			setEmail("");
		} else {
			setSubscribeMessage("Invalid email format!");
		}
	};

	const imgSend = () => {
		return (
			<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2.1,44.5l4.4-16.3h18.6c0.5,0,1-0.5,1-1v-2c0-0.5-0.5-1-1-1H6.5l-4.3-16l0,0C2.1,8,2,7.7,2,7.4
					C2,6.7,2.7,6,3.5,6.1c0.2,0,0.3,0.1,0.5,0.1l0,0l0,0l0,0l45,18.5c0.6,0.2,1,0.8,1,1.4s-0.4,1.1-0.9,1.3l0,0L4,46.4l0,0
					c-0.2,0.1-0.4,0.1-0.6,0.1C2.6,46.4,2,45.8,2,45C2,44.8,2,44.7,2.1,44.5L2.1,44.5z"
				/>
			</svg>
		);
	};

	const imgPolygon = () => {
		return (
			<svg stroke="currentColor" fill="#8247e5" strokeWidth="0" viewBox="0 0 38.4 33.5" xmlns="http://www.w3.org/2000/svg" className="h-4 inline">
				<path
					d="M29,10.2c-0.7-0.4-1.6-0.4-2.4,0L21,13.5l-3.8,2.1l-5.5,3.3c-0.7,0.4-1.6,0.4-2.4,0L5,16.3
					c-0.7-0.4-1.2-1.2-1.2-2.1v-5c0-0.8,0.4-1.6,1.2-2.1l4.3-2.5c0.7-0.4,1.6-0.4,2.4,0L16,7.2c0.7,0.4,1.2,1.2,1.2,2.1v3.3l3.8-2.2V7
					c0-0.8-0.4-1.6-1.2-2.1l-8-4.7c-0.7-0.4-1.6-0.4-2.4,0L1.2,5C0.4,5.4,0,6.2,0,7v9.4c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7
					c0.7,0.4,1.6,0.4,2.4,0l5.5-3.2l3.8-2.2l5.5-3.2c0.7-0.4,1.6-0.4,2.4,0l4.3,2.5c0.7,0.4,1.2,1.2,1.2,2.1v5c0,0.8-0.4,1.6-1.2,2.1
					L29,28.8c-0.7,0.4-1.6,0.4-2.4,0l-4.3-2.5c-0.7-0.4-1.2-1.2-1.2-2.1V21l-3.8,2.2v3.3c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7
					c0.7,0.4,1.6,0.4,2.4,0l8.1-4.7c0.7-0.4,1.2-1.2,1.2-2.1V17c0-0.8-0.4-1.6-1.2-2.1L29,10.2z"
				/>
			</svg>
		);
	};

	const imgArbitrum = () => {
		return (
			<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 2500 2500" xmlns="http://www.w3.org/2000/svg" className="h-4 inline">
				<rect fill="none" class="st0" width="2500" height="2500"></rect>
				<path
					fill="#213147"
					d="M226,760v980c0,63,33,120,88,152l849,490c54,31,121,31,175,0l849-490c54-31,88-89,88-152V760
					c0-63-33-120-88-152l-849-490c-54-31-121-31-175,0L314,608c-54,31-87,89-87,152H226z"
				/>
				<path fill="#12AAFF" d="M1435,1440l-121,332c-3,9-3,19,0,29l208,571l241-139l-289-793C1467,1422,1442,1422,1435,1440z" />
				<path fill="#12AAFF" d="M1678,882c-7-18-32-18-39,0l-121,332c-3,9-3,19,0,29l341,935l241-139L1678,883V882z" />
				<path
					fill="#9DCCED"
					d="M1250,155c6,0,12,2,17,5l918,530c11,6,17,18,17,30v1060c0,12-7,24-17,30l-918,530c-5,3-11,5-17,5
					s-12-2-17-5l-918-530c-11-6-17-18-17-30V719c0-12,7-24,17-30l918-530c5-3,11-5,17-5l0,0V155z M1250,0c-33,0-65,8-95,25L237,555
					c-59,34-95,96-95,164v1060c0,68,36,130,95,164l918,530c29,17,62,25,95,25s65-8,95-25l918-530c59-34,95-96,95-164V719
					c0-68-36-130-95-164L1344,25c-29-17-62-25-95-25l0,0H1250z"
				/>
				<polygon fill="#213147" points="642,2179 727,1947 897,2088 738,2234" />
				<path fill="#FFFFFF" d="M1172,644H939c-17,0-33,11-39,27L401,2039l241,139l550-1507c5-14-5-28-19-28L1172,644z" />
				<path fill="#FFFFFF" d="M1580,644h-233c-17,0-33,11-39,27L738,2233l241,139l620-1701c5-14-5-28-19-28V644z" />
			</svg>
		);
	};

	return (
		<footer>
			<div className="container mx-auto py-12">
				<div className="grid grid-cols-1 gap-12 lg:grid-cols-9 lg:gap-0">
					<div className="mx-auto lg:col-span-2">
						<div className="flex items-center text-xl mb-4">
							<img src={imgLogo} className="mr-4" loading="lazy" alt="logo" />
							<span className="baumans">Kommunitas</span>
						</div>
						<div className="social-box grid grid-cols-6 gap-1">
							{socialData.map((val, idx) => (
								<a key={idx} href={val.link} target="_blank" rel="noreferrer">
									<img src={val.image} alt={val.name} loading="lazy" />
								</a>
							))}
						</div>
						<div className="mt-4">
							<form onSubmit={(e) => subscribeNewsletter(e)}>
								<label className="block mb-1 text-sm font-bold">Don’t miss any news and updates from Kommunitas.</label>
								{!!subscribeMessage ? (
									<div className="newsletter-msg">{subscribeMessage}</div>
								) : (
									<div className="newsletter-box inline-flex">
										<input required type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
										<button type="submit" className="button">
											<div className="w-4">{imgSend()}</div>
										</button>
									</div>
								)}
							</form>
						</div>
					</div>
					<div className="mx-auto lg:col-span-5">
						<div className="grid grid-cols-2 gap-x-8 gap-y-4 xl:grid-cols-3">
							{weblinkData.footer.map((val, idx) => (
								<a key={idx} href={val.link} target="_blank" rel="noreferrer">
									{val.name}
								</a>
							))}
						</div>
					</div>
					<div className="mx-auto lg:col-span-2">
						<div
							className="trustpilot-widget pb-6"
							data-locale="en-US"
							data-template-id="5419b6a8b0d04a076446a9ad"
							data-businessunit-id="61ad0044296732d7cb6b9822"
							data-style-height="24px"
							data-style-width="100%"
							data-theme="dark"
							data-min-review-count="10"
						>
							<a href="https://www.trustpilot.com/review/kommunitas.net" target="_blank" rel="noreferrer">
								Trustpilot
							</a>
						</div>
						<div className="grid grid-cols-2 gap-4">
							{assetData.map((val, idx) => (
								<a key={idx} href={val.link} target="_blank" rel="noreferrer">
									<img width={150} height={28} src={val.image} alt={val.name} loading="lazy" />
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="bg-black">
				<div className="container mx-auto w-full p-1 text-sm">
					All Rights Reserved - <span className="baumans">Kommunitas</span> {new Date().getFullYear()} | Powered by Polygon {imgPolygon()} &nbsp;and&nbsp; Arbitrum {imgArbitrum()}
					&nbsp;|&nbsp;
					<a href="https://docs.kommunitas.net/legal/disclaimer" className="underline font-bold" target="_blank" rel="noreferrer">
						Disclaimer
					</a>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
