import { Link } from "react-router-dom";
import cardData from "../constants/static/card.json";
import { useEffect } from "react";

export default function Home() {
	useEffect(() => {
		document.title = `Kommunitas Earn: How to Earn Money with Crypto`;
	}, []);

	return (
		<main>
			<section className="gradient-1">
				<div className="mx-auto py-4 md:py-12">
					<div className="max-width-640 mx-auto px-4 text-center">
						<div className="lg:px-8">
							<h1 className="mb-4 txt-shadow">
								<div className="text-3xl lg:text-6xl">Kommunitas Earn</div>
								<div className="text-1xl lg:text-3xl">How to Earn Money in Cryptocurrency</div>
							</h1>
							<p className="mb-4 px-4">Earn rewards not only in $KOM token but other project’s token as well as stable coin!</p>
						</div>
					</div>
				</div>
			</section>
			<section className="box-parent relative z-10 min-h-full">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-3">
					{cardData.map((card, index) => (
						<div className={`${card.boxStyle} min-h-[120px] md:min-h-[150px]`} key={index}>
							<div className="text-left my-2">
								<h2 className="text-sm md:text-xl mb-1 md:mb-4">{card.name}</h2>
								<p className="w-2/3 md:w-2/3 text-xs">{card.desc}</p>
								<div className="absolute bottom-[1rem] left-[15px] mt-1 md:mt-3">
									<Link to={card.link}>
										<button className={`btn ${card.buttonStyle} text-sm`}>Read More</button>
									</Link>
								</div>
							</div>
						</div>
					))}
				</div>
			</section>
			<div className="mb-6 mx-auto text-justify seo-article">
				<h2 className="text-2xl txt-secondary text-center">Kommunitas: How to Earn Money with Crypto</h2>
				<p className="text-white">Are you interested in learning how to earn money with crypto? Kommunitas has the perfect opportunity for you!</p>
				<p className="text-white">
					As a decentralized crowdfunding ecosystem for Web 3.0 ventures, Kommunitas aims to provide easy access to innovative projects that could be enticing for both investors and
					creators, nurturing a community based on collaboration and mutual support.
				</p>
				<p className="text-white">
					Kommunitas can be an excellent starting point for people who are interested in crypto but are not sure where to start. There are several ways in which our platform can help you
					learn, grow, and become more proficient when it comes to crypto investments and projects related to it.
				</p>
				<p className="text-white">Learn more about how to earn crypto online at a fair and transparent platform, such as Kommunitas.</p>
				<h2 className="text-xl txt-secondary text-center">Earning Crypto for Free</h2>
				<p className="text-white">
					Kommunitas offers you the opportunity to earn crypto for free or with minimal investment, based on your current budget, interests or capacity. We would like to provide a platform
					where everyone can get started in the crypto world with little risk, ensuring they learn the essentials of blockchain technology and decentralized applications, as well as smart
					contracts and everything related to crypto investments.
				</p>
				<p className="text-white">If you would like to know how to earn crypto for free, Kommunitas is the perfect place to get started. Here’s why.</p>
				<h2 className="text-xl txt-secondary">Tier-less System</h2>
				<p className="text-white">
					If you take a look at the current panorama of crypto platforms in the modern day, you'll see that most of them hide certain features from their audience by "locking" them away
					using a tiered system. That prevents people from starting their crypto journey in the way they find most suitable for it.
				</p>
				<p className="text-white">
					Kommunitas has no tiers. Therefore, there are no barriers that may prevent you from experiencing the wonders of the world of crypto. As long as you are a registered KOM holder
					within our platform, you will be able to take part in all the features that we offer in our Launchpad.
				</p>
				<h2 className="text-xl txt-secondary">Airdrop Events</h2>
				<p className="text-white">
					Kommunitas is a highly active community. We are constantly organizing and holding airdrop events where our users may earn rewards by taking part in them. Even if you haven't staked
					substantial quantities, you'll still be able to take part in these projects and might be able to win good money.
				</p>
				<p className="text-white">
					We often gift tokens in our Airdrop events, allowing our users to increase their revenue without necessarily staking or risking too much of their money. These events are an
					excellent opportunity for those who would like to learn how to make money with cryptocurrency but are skeptical of the benefits that these projects could bring to their lives.
				</p>
				<p className="text-white text-center">
					Take a look at our
					<Link to="airdrops" className="txt-secondary underline px-1">
						upcoming Airdrop events
					</Link>
					and join us to become one of the lucky winners!
				</p>
				<h2 className="text-xl txt-secondary">Vote, Share, and Buy Rewards</h2>
				<p className="text-white">
					If you are interested in knowing how to earn a yield on crypto, then our "Vote, Share, and Buy" rewards system may help you increase what you already own in different ways. All you
					have to do is to have staked a minimum of 3,001 KOM to obtain KOMV tokens and gain the ability to vote on projects that are of interest to you. Once you participate in that
					project's sale period, you will be eligible for a token bonus!
				</p>
				<p className="text-white">
					This is an excellent way to earn additional rewards while also continuing to offer support to our platform. It helps us identify the projects that our public believes are the most
					interesting or captivating, allowing us to continue offering such a great platform to our users.
				</p>
				<p className="text-white">
					Of course, the more you vote, share, and buy, the more rewards you earn. Make sure to follow all the necessary steps to be able to get your rewards. Learning how to earn crypto
					rewards was never so easy!
				</p>
				<p className="text-white text-center">
					Get to know more about our
					<Link to="vote-share-buy-rewards" className="txt-secondary underline px-1">
						Vote, Share, and Buy Rewards
					</Link>
					.
				</p>
				<h2 className="text-xl txt-secondary">Educate Yourself on How to earn Free Crypto: Learn2Earn</h2>
				<p className="text-white">
					Earning crypto may feel a bit daunting for some, but it doesn’t have to be. At Kommunitas, we make it possible for you to take part in the cryptocurrency world in the way you feel
					most comfortable with, whether you prefer staking small amounts or would like to take higher risks. Whatever your choice is, Kommunitas has an open platform for your style.
				</p>
				<p className="text-white">
					We aim to provide a website where people cannot only take part in cryptocurrency stakes and investments but also a platform where you can learn more about crypto and everything
					related to it.
				</p>
				<p className="text-white">
					This is why we've chosen to start Learn2Earn, a part of our website dedicated to explaining and educating our users about the basics of crypto and how to earn money with crypto
					from the very beginning. You will also find information regarding our offers, events, and more!
				</p>
				<p className="text-white">
					If you have specific doubts on a particular topic or would like to know more about Kommunitas, $KOM, and everything else that we offer, do not hesitate to contact us! Our team is
					available for you 24/7.
				</p>
				<h2 className="text-xl txt-secondary text-center">Earning Passive Income with Crypto</h2>
				<p className="text-white">
					Many people are interested in learning how to earn passive income with crypto. This is entirely possible, but the ways in which you can achieve such progress are different in every
					platform. As for Kommunitas, we offer a few methods that may allow you to earn passive income in the long run. Here’s what you need to know.
				</p>
				<h2 className="text-xl txt-secondary">Revenue Sharing</h2>
				<p className="text-white">
					Not all platforms offer a way of revenue sharing. However, at Kommunitas, we like to provide our users with different incentives to continue taking part in our platform. That’s why
					private partners are able to grab a part of the fees we collect from our FCFS and Community Rounds. These funds will be shared with all the users who are part of the private
					partners group during each quarter of the year.
				</p>
				<p className="text-white">
					You can take part in our Revenue Sharing program by staking a minimum of 500,000 KOM in your current account.
					<b className="px-1">You will be able to collect all the rewards you earn through this system via USDC!</b>
				</p>
				<p className="text-white">However, the perks don’t end there – if you stake over 10,000,000 KOM, you will be able to become a KOM Millionaire Partner. </p>

				<h2 className="text-xl txt-secondary">Become a Millionaire Partner</h2>
				<p className="text-white">
					You can become a Millionaire Partner by staking a minimum of 10,000,000 KOM tokens. Once you’ve met the eligibility criteria, you will be eligible to be a part of the group. This
					is an excellent opportunity to earn passive income!
				</p>
				<div className="text-white">
					At Kommunitas, we would like to provide people with rewards for the effort and time they invest while taking part in our platform. That’s why the top token stakes may be rewarded
					via the Monthly Millionaire Partners program, which allows them to enjoy several benefits compared to other users:
				</div>
				<ul className="text-white">
					<li>
						We take fees of between 3 and 5 percent for the projects that users launch on our platform. However, we have decided to share 5 percent of this fund with our Millionaire
						Partners every month, allowing you to earn money as long as you meet the eligibility criteria.
					</li>
					<li>
						Millionaire Partners also have access to exclusive deals and offers that may not be available immediately (or even at all) for regular users. Therefore, you could grab more
						than one opportunity to increase your revenue without taking big risks.
					</li>
					<li>
						Kommunitas often hold partnered projects and events, and sometimes, there are leftover tokens. Instead of leaving them to waste, we distribute them with our Millionaire
						Partners.
					</li>
				</ul>
				<p className="text-white">
					These and other perks are waiting for you as part of the Monthly Millionaire Partners Program. Several exclusive updates and offers are only available for those who are members.
					What are you waiting for? Start staking today and become a Kommunitas Millionaire Partner!
				</p>
				<h2 className="text-xl txt-secondary text-center">Why Choose Kommunitas?</h2>
				<p className="text-white">
					Kommunitas is still a growing platform, being launched in 2021. However, after being for two years in the industry, thousands of users worldwide engage in our offers and community,
					enjoying the rewards and helping us continue improving the online ecosystem that we have created.
				</p>
				<p className="text-white">Get to know the different reasons why people have chosen us as their main cryptocurrency-related platform. </p>
				<h2 className="text-xl txt-secondary">Transparency</h2>
				<p className="text-white">
					Transparency is a must-have value that we always prioritize. We ensure to provide our users with al the pertinent information regarding their stakes and investments, no matter what
					you’re interested in knowing. There is public information regarding our performance and token distribution, as well as proper information on how we handle events and what you can
					expect from them. At Kommunitas, we prioritize transparency because we understand that it is the key to building a prosperous and long-lasting platform. Still, if you have any
					questions, make sure to contact us.
				</p>
				<h2 className="text-xl txt-secondary">Quick and Easy Transactions</h2>
				<p className="text-white">
					All transactions in Kommunitas are completed in record time, as you can expect from a cryptocurrency platform. We look forward to collaborating with you and providing a platform
					where you can learn about crypto and earn money while at it. We understand that some users like to move and switch from one platform to another for more opportunities, which is why
					we aim to provide an easy and quick way to complete transactions, whether they are deposits or withdrawals.
				</p>
				<p className="text-white">You can withdraw your KOM tokens from any of the exchanges we list on our website, such as Binance, IndoEx, or CoinBase, to name a few. </p>
				<h2 className="text-xl txt-secondary">Innovation</h2>
				<p className="text-white">
					Kommunitas is a one-of-a-kind platform that intends to drive innovation. We operate using a decentralized system that focuses on community and collaboration. Being part of
					Kommunitas is choosing to be on the side of innovation and progress, as we aim to provide easy access to the most innovative and diverse Web 3.0 projects.
				</p>
				<p className="text-white">
					Rewards and unique opportunities for investment, among several other perks, are some of the ways we utilize to foster a supportive and inclusive ecosystem for blockchain operations
					and applications.
				</p>
				<h2 className="text-xl txt-secondary">Diversity</h2>
				<p className="text-white">
					Despite being around for a couple of years, Kommunitas is aiming to provide a platform that welcomes diversity in regard to projects and community. Not only will you have access to
					a wide range of projects and events that might be of interest to you, but you will also be able to interact with a wide community of users with similar interests and goals. Rewards
					Last but not least, rewards are the pinnacle of what we offer at Kommunitas. We want every user to feel appreciated and fulfilled with their efforts, which is why we utilize
					different reward systems for those who choose to engage in our platform, regardless of how little or how much they decide to invest in what we have to offer.
				</p>
				<h2 className="text-xl txt-secondary">Rewards</h2>
				<p className="text-white">
					Last but not least, rewards are the pinnacle of what we offer at Kommunitas. We want every user to feel appreciated and fulfilled with their efforts, which is why we utilize
					different reward systems for those who choose to engage in our platform, regardless of how little or how much they decide to invest in what we have to offer.
				</p>
				<h2 className="text-xl txt-secondary text-center">Sign Up Today!</h2>
				<p className="text-white">
					Are you ready to take part in the most innovative, exciting, and rewarding platform for Web 3.0 ventures? Sign up for Kommunitas today and start learning how to earn money with
					crypto!
				</p>
			</div>
		</main>
	);
}
