import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import APIHelper from "../../helpers/APIHelper";
import arb from "../../assets/img/tokens/arb.svg";
import bnb from "../../assets/img/tokens/bnb.png";
import polygon from "../../assets/img/tokens/polygon.png";

export default function Quarterly() {
	const { user, isConnected } = useContext(AuthContext);
	const [quarterly, setQuarterly] = useState([]);
	const [loading, setLoading] = useState(false);

	const getQuarterly = () => {
		setLoading(true);
		APIHelper.getQuarterly(user?.address)
			.then((res) => {
				setQuarterly(res.result);
			})
			.catch((err) => {
				console.err(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getQuarterly();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConnected]);

	return (
		<div className="main-container">
			<div className="text-left">
				<h1 className="title-page">Quarterly Revenue Sharing</h1>
				<nav>
					<ul className="breadcrumb text-sm">
						<li>
							<Link to="/" className="txt-ternary">
								Home
							</Link>
						</li>
						<li className="active">Quarterly Revenue Sharing</li>
					</ul>
				</nav>
			</div>
			<section className="box-parent bg-quarterly-revenue my-5 p-20">
				<div className="box-desc">
					<p className="w-2/3 md:w-1/2 leading-[15px] md:leading-[25px] text-xl text-white">
						Quarterly Revenue Sharings snapshot will be taken every end of each Quarter, 23:59 PM UTC. (31st March, 30th June, 30th September, 31st December).
					</p>
				</div>
				<div className="my-5">
					<div className="box-desc text-[#C8CAEF]">The Treasury Wallets for Quarterly Revenue Sharing are:</div>
					<div className="flex my-5 gap-x-2">
						<a href="https://polygonscan.com/address/0xFac225d946De03005610aa8F0869280791bE7bAD" target="_blank" rel="noreferrer">
							<button className="flex items-center justify-center btn btn-shadow border-2 border-[#6889FF]">
								<img src={polygon} alt="Polygon Matic" className="w-8 h-8" />
								Polygon
							</button>
						</a>
						<a href="https://bscscan.com/address/0x2ca0020DB99c15b0197B88BF4aac542430159067" target="_blank" rel="noreferrer">
							<button className="flex items-center justify-center btn btn-shadow border-2 border-[#6889FF]">
								<img src={bnb} alt="BSC" className="w-8 h-8" />
								BSC
							</button>
						</a>
						<a href="https://arbiscan.io/address/0xFac225d946De03005610aa8F0869280791bE7bAD" target="_blank" rel="noreferrer">
							<button className="flex items-center justify-center btn btn-shadow border-2 border-[#6889FF]">
								<img src={arb} alt="ARB" className="w-8 h-8 p-1" />
								ARB
							</button>
						</a>
					</div>
				</div>
				<div className="my-5">
					<div className="box-desc text-[#C8CAEF]">Full details of Quarterly Revenue Sharing can be found here :</div>
					<div className="my-5">
						<a href="https://docs.kommunitas.net/staking/quarterly-revenue-sharing" target="_blank" rel="noreferrer">
							<button className="btn btn-shadow mr-5">View Details</button>
						</a>
					</div>
				</div>
				<div>
					{!loading ? (
						<div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
							{quarterly.reverse().map((item, index) => (
								<div key={index} className="relative">
									<div className={"bg-box rounded-xl px-6 py-8 md:py-12 md:px-14 " + (isConnected && !item.winner ? "opacity-25" : "")}>
										<img src={item.banner} alt="Revenue Sharing" className="rounded-xl mx-auto" width={500} height={100} />
										<a href={item.link} target="_blank" rel="noreferrer">
											<button className="w-full btn btn-read-more my-5">View Details</button>
										</a>
									</div>
									{isConnected && !item.winner && (
										<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
											<p className="text-white">Not Winner</p>
										</div>
									)}
								</div>
							))}
							<div className="flex items-center justify-center bg-box rounded-xl monthly-coming-soon">
								<p className="text-lg opacity-100">Coming Soon</p>
							</div>
						</div>
					) : (
						<div className="flex items-center justify-center">Loading data...</div>
					)}
				</div>
			</section>
		</div>
	);
}
