import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/AuthProvider";
import APIHelper from "../../helpers/APIHelper";
import Pagination from "../../components/Paginantion";

const ViewLPStakers = ({ lpstakers, status }) => {
	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-5">
				{lpstakers.map((item, index) => (
					<div
						key={index}
						className="relative rounded py-5 px-5"
						style={{
							background: `url('${item.banner}') no-repeat center center / 100% 100%`,
							height: "145px",
						}}
					>
						<div className="flex items-center">
							<img src={item.logo} alt={item.nama} width={40} height={40} className="rounded-full bg-blue-900 p-2" />
							<h3 className="text-xl ml-3">⭐️ {item.nama}</h3>
						</div>
						<div className="mt-3">
							<div className="text-xs text-[#C8CAEF]" dangerouslySetInnerHTML={{ __html: item.info.replace(/\r\n|\n|\r/gm, "<br />") }} />
						</div>
						<div className="absolute left-0 bottom-2 w-full text-center">
							<a href={item.link}>
								<button className="w-[100px] bg-[#0186F6] rounded-full px-2 text-sm">View Details</button>
							</a>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default function LPStaking() {
	const itemsPerPage = 10;
	const [isLoading, setIsLoading] = useState(false);
	const [LPStakers, setLPStakers] = useState([]);
	const [query, setQuery] = useState("");
	const [pageCount, setPageCount] = useState(0);
	const [currentPageItems, setCurrentPageItems] = useState([]);
	const { user, isConnected } = useContext(AuthContext);

	const getLPStaking = async () => {
		setIsLoading(true);
		const response = await APIHelper.getLPStaker(user?.address);
		if (response.status === "success") {
			setLPStakers(response.result);
			setIsLoading(false);
		}
		setLPStakers([]);
	};

	const handlePageClick = (event) => {
		const selectedPage = event.selected;
		const offset = (selectedPage * itemsPerPage) % LPStakers.length;
		setPageCount(Math.ceil(LPStakers.length / itemsPerPage));
		setCurrentPageItems(LPStakers.slice(offset, offset + itemsPerPage));
	};

	const search = (query) => {
		const filtered = LPStakers.filter((airdrop) => {
			return airdrop.nama.toLowerCase().includes(query.toLowerCase());
		});
		setPageCount(Math.ceil(filtered.length / itemsPerPage));
		setCurrentPageItems(filtered.slice(0, itemsPerPage));
	};

	useEffect(() => {
		if (query) {
			search(query);
		} else {
			setPageCount(Math.ceil(LPStakers.length / itemsPerPage));
			setCurrentPageItems(LPStakers.slice(0, itemsPerPage));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		setPageCount(Math.ceil(LPStakers.length / itemsPerPage));
		setCurrentPageItems(LPStakers.slice(0, itemsPerPage));
	}, [LPStakers]);

	useEffect(() => {
		getLPStaking();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConnected]);

	useEffect(() => {
		document.title = `LP Staking | Kommunitas`;
	}, []);

	return (
		<div className="main-container">
			<div className="text-left">
				<h1 className="title-page">LP Staking</h1>
				<nav>
					<ul className="breadcrumb text-sm">
						<li>
							<Link to="/" className="txt-ternary">
								Home
							</Link>
						</li>
						<li className="active">LP Staking</li>
					</ul>
				</nav>
			</div>
			<section className="box-parent bg-lp-farming my-5">
				<div className="box-desc flex justify-start items-center">
					<div>
						<p className="w-2/3 md:w-1/2 text-sm md:text-lg text-justify">
							Kommunitas is launching the LP Staking Program to
							boost liquidity and rewards within its community
							ecosystem. Central to this effort is the SKOM token,
							which connects Web 3.0 projects with the community.
							The program aims
							attract more investors by
							enhancing SKOM's availability and liquidity via a
							Liquidity Pool on Quickswap V2.
						</p>
						<p className="w-2/3 md:w-1/2 text-sm md:text-lg text-justify mt-8">
							LP token stakers can look forward to benefits like extra
							allocations in Initial KOM Offerings (IKOS), monthly
							revenue sharing, free tokens, and earnings from
							trading fees. With a focus on increasing engagement
							and providing rewards for their contributions,
							Kommunitas is inviting its members to be part of a
							significant movement towards enhancing community
							empowerment and setting new benchmarks in
							decentralized crowdfunding.
						</p>
					</div>
				</div>
				<div className="text-center mt-[5%]">
					<h3 className="text-3xl txt-shadow">Distributed Rewards</h3>
				</div>

				{isLoading ? (
					<div className="flex items-center justify-center">Loading data ...</div>
				) : (
					<div className="box-parent mt-10">
						<div className="flex justify-end">
							<input type="text" placeholder="Search by project name" className="w-1/2 rounded-xl my-3 px-2 py-1 text-black" onChange={(e) => setQuery(e.target.value)} />
						</div>
						{currentPageItems.length > 0 ? (
							<div className="mt-5">
									<ViewLPStakers lpstakers={currentPageItems} />
								{pageCount > 1 && (
									<div className="mt-5">
										<Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
									</div>
								)}
							</div>
						) : (
							<div className="mt-10 flex items-center justify-center">
								<p className="text-center text-2xl">No distributed projects available at this time.</p>
							</div>
						)}
					</div>
				)}
			</section>
		</div>
	);
}
